import { config } from "@config/config";
import { defaultMetaTagData } from "@config/default-meta-tag-data";
import Head from "next/head";
import { useRouter } from "next/router";

export interface MetaTagDataProps {
    canonicalUrl?: string;
    image?: string;
    keywords?: string;
    metaDescription?: string;
    metaRobots?:
        | "index"
        | "noindex"
        | "follow"
        | "nofollow"
        | "index, follow"
        | "index, nofollow"
        | "noindex, follow"
        | "noindex, nofollow";
    metaTitle?: string;
    ogDescription?: string;
    ogTitle?: string;
    replaceFullTitle?: boolean;
    title: string;
    twitterCard?: string;
    twitterImageAlt?: string;
    twitterUserName?: string;
    type?: "article" | "blog" | "profile" | "website";
}

// eslint-disable-next-line react/destructuring-assignment
export function MetaTagData({
    canonicalUrl,
    image = defaultMetaTagData.image,
    keywords = defaultMetaTagData.keywords,
    metaDescription = defaultMetaTagData.metaDescription,
    metaRobots,
    metaTitle = defaultMetaTagData.metaTitle,
    ogDescription,
    ogTitle,
    replaceFullTitle,
    title,
    twitterCard,
    twitterImageAlt,
    twitterUserName,
    type = defaultMetaTagData.type,
}: MetaTagDataProps) {
    const { asPath } = useRouter();

    return (
        <Head>
            <title>{replaceFullTitle ? title : (title ? `${title} | ` : "") + defaultMetaTagData.siteName}</title>
            <meta charSet="utf-8" />
            <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
            <meta name="title" content={metaTitle} />
            <meta name="description" content={metaDescription} />
            {keywords && <meta name="keywords" content={keywords} />}
            {metaRobots && <meta name="robots" content={metaRobots} />}
            <meta property="og:title" content={ogTitle ?? metaTitle} />
            <meta property="og:description" content={ogDescription ?? metaDescription} />
            {image && <meta property="og:image" content={`${config.FRONTEND_URL}${image}`} />}
            <meta property="og:image:width" content={String(defaultMetaTagData.imageWidth)} />
            <meta property="og:image:height" content={String(defaultMetaTagData.imageHeight)} />
            <meta property="og:url" content={canonicalUrl ?? config.FRONTEND_URL + asPath} />
            <meta property="og:site_name" content={defaultMetaTagData.siteName} />
            <meta property="og:type" content={type} />
            {twitterCard && <meta name="twitter:card" content={twitterCard} />}
            {twitterImageAlt && <meta name="twitter:image:alt" content={twitterImageAlt} />}
            {twitterUserName && <meta name="twitter:site" content={twitterUserName} />}
            {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        </Head>
    );
}
