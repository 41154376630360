import type { Nullish } from "@uxf/core/types";
import { isEmpty } from "@uxf/core/utils/is-empty";
import { isNil } from "@uxf/core/utils/is-nil";
import { isNotNil } from "@uxf/core/utils/is-not-nil";
import type { Location } from "@uxf/smart-address/v1/types";

function formatAddressShort(address: Location | Nullish): string {
    if (isNil(address)) {
        return "";
    }

    const { obecNazev, uliceNazev, cisloDomovni, cisloOrientacni } = address;

    const number =
        isNotNil(cisloDomovni) && isNotNil(cisloOrientacni)
            ? `${cisloDomovni}/${cisloOrientacni}`
            : `${cisloDomovni ?? cisloOrientacni ?? ""}`;

    if (isNotNil(obecNazev) && isNotNil(uliceNazev)) {
        return `${uliceNazev}${isNotNil(number) && !isEmpty(number) ? " " + number : ""}, ${obecNazev}`;
    }

    return obecNazev ?? "";
}

function formatObec(obecNazev: string | Nullish, cast2: string | Nullish): string {
    if (isNotNil(obecNazev) && isNil(cast2)) {
        return obecNazev;
    }

    if (isNil(obecNazev) || isNil(cast2)) {
        return "";
    }

    if (cast2 === obecNazev || cast2.startsWith(obecNazev)) {
        return cast2;
    }

    return `${obecNazev} – ${cast2}`;
}

function formatAddress(address: Location | Nullish): string {
    if (isNil(address)) {
        return "";
    }

    const { obecNazev, castObceNazev, uliceNazev, cisloDomovni, cisloOrientacni, cisloOrientacniZnak, psc } = address;

    let ulice = uliceNazev ?? castObceNazev ?? obecNazev ?? "";

    if (isNotNil(cisloDomovni)) {
        ulice += ` ${cisloDomovni}`;
    }

    if (isNotNil(cisloOrientacni)) {
        ulice += `/${cisloOrientacni}`;
    }

    if (isNotNil(cisloOrientacniZnak)) {
        ulice += cisloOrientacniZnak;
    }

    const obec = formatObec(obecNazev, castObceNazev);

    return [ulice, obec, psc ?? ""].join(", ");
}

export const AddressFormatter = {
    formatAddress,
    formatAddressShort,
};
