import { QueryParams, UxfGetServerSideProps, UxfGetStaticProps } from "@uxf/router";
import type { GetStaticPaths as NextGetStaticPaths, PreviewData as NextPreviewData } from "next/types";
import router, { RouteList } from "./routes";

export const { route, routeToUrl, createSitemapGenerator, useQueryParams, routes } = router;

export type GetStaticProps<
    Route extends keyof RouteList,
    Props extends { [key: string]: any } = { [key: string]: any },
    PreviewData extends NextPreviewData = NextPreviewData,
> = UxfGetStaticProps<RouteList, Route, Props, PreviewData>;

export type GetStaticPaths<Route extends keyof RouteList> = NextGetStaticPaths<QueryParams<RouteList, Route>>;

export type GetServerSideProps<
    Route extends keyof RouteList,
    Props extends { [key: string]: any } = { [key: string]: any },
    PreviewData extends NextPreviewData = NextPreviewData,
> = UxfGetServerSideProps<RouteList, Route, Props, PreviewData>;
