import { MapProvider } from "@main-zone/components/map-provider-example/types";
import { createRouter } from "@uxf/router";

export type RouteList = {
    homepage: null;
    "address-detail-page": {
        mapProvider?: MapProvider;
        smartAddressId: number;
        slug?: string;
    };
    "sitemap-xml": null;
};

export default createRouter<RouteList>({
    homepage: "/",
    "address-detail-page": "/[smartAddressId]/[slug]/[[...mapProvider]]",
    "sitemap-xml": "/sitemap.xml",
});
