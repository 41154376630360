import { routeToUrl } from "@app-routes";
import { Icon } from "@uxf/ui/icon";
import Link from "next/link";
import { ReactNode } from "react";

interface Props {
    children: ReactNode;
}

export function Layout(props: Props) {
    return (
        <div className="flex min-h-screen flex-col">
            <nav className="bg-sa-dark-blue shrink-0 text-white">
                <div className="container flex h-20 items-center max-xs:px-4">
                    <Link className="sa-h-f-size header__logo" href={routeToUrl("homepage")}>
                        <img alt="" className="logo" src="/images/smart-address-icons/logo.svg" />
                        <p>Smart Address</p>
                    </Link>
                </div>
            </nav>
            {props.children}
            <footer className="footer">
                <div className="footer__wrapper container">
                    <Link href={routeToUrl("homepage")} className="footer__logo">
                        <img alt="" className="logo" src="/images/smart-address-icons/logo.svg" />
                        <p className="logo__text">Smart Address</p>
                    </Link>
                    <div className="footer__contact--wrapper">
                        <div className="footer__block">
                            <h3 className="footer__h3">Kontakt</h3>
                            <div className="footer__block--text">
                                <a href="tel:+420724187025" className="footer__icon" rel="noreferrer" target="_blank">
                                    <Icon name="phone" size={24} />
                                    724 187 025
                                </a>
                                <a href="mailto://tv@uxf.cz" className="footer__icon" rel="noreferrer" target="_blank">
                                    <Icon name="at-sign" size={24} />
                                    tv@uxf.cz
                                </a>
                                <a
                                    href="https://www.instagram.com/ux_fans/?igshid=YmMyMTA2M2Y%3D"
                                    className="footer__icon"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <Icon name="instagram" size={24} />
                                    ux_fans
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/ux-fans-s-r-o/?originalSubdomain=cz"
                                    className="footer__icon"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <Icon name="linkedin" size={24} />
                                    UX Fans s.r.o
                                </a>
                            </div>
                        </div>
                        <div className="footer__block">
                            <h3 className="footer__h3">Adresa</h3>
                            <div className="footer__block--text">
                                <p>UX Fans s.r.o.</p>
                                <p>Sovova 584/2, Liberec</p>
                                <a href="https://www.uxf.cz/" rel="noreferrer" target="_blank" className="footer__link">
                                    www.uxf.cz
                                </a>
                            </div>
                        </div>
                        <div className="footer__block">
                            <h3 className="footer__h3">Reference</h3>
                            <div className="footer__block--text">
                                <a
                                    href="https://www.kalkulator.cz/"
                                    rel="noreferrer"
                                    target="_blank"
                                    className="footer__link"
                                >
                                    Kalkulátor.cz
                                </a>
                                <a
                                    href="https://www.sousede.cz/"
                                    rel="noreferrer"
                                    target="_blank"
                                    className="footer__link"
                                >
                                    Sousedé.cz
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__copy container">
                    <span className="footer__line">.</span>
                    <div className="footer__text">
                        <p className="footer__copy--text">&copy; 2024 UX Fans s.r.o</p>
                        <p className="footer__copy--text">
                            Jakékoliv užití obsahu je bez souhlasu společnosti UX Fans zakázáno.
                        </p>
                    </div>
                </div>
            </footer>
        </div>
    );
}
