import { config } from "@config/config";
import { ICONS_VERSION } from "@generated/icons";
import Head from "next/head";

export function Prefetch() {
    return (
        <Head>
            {config.GTM_ID && (
                <>
                    <link rel="dns-prefetch preconnect" href="https://www.googletagmanager.com" />
                    <link rel="dns-prefetch preconnect" href="https://www.google-analytics.com" />
                </>
            )}
            <link
                as="image"
                crossOrigin="anonymous"
                href={`/icons-generated/_icon-sprite.svg?v=${ICONS_VERSION}`}
                rel="prefetch"
            />
        </Head>
    );
}
